/* Facts Container */
.facts-container {
  color: white; /* Default text color */
  border-radius: 5px; /* Smooth corners */
  padding: 10px;
  width: 300px;
  max-width: 300px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  text-align: left; /* Align text to the left for readability */
  min-height: 50px; /* Ensure space for the loader and prevent jumping */
  position: relative; /* To keep loader centered and avoid overlap */
  margin-top: 250px; /* Adjust to give space under spinner */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add subtle shadow for a cleaner look */
  background-color: #1a1a1a; /* Dark background for contrast */
  box-sizing: border-box; /* Ensure padding is included in width calculations */
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease-in; /* Smooth fade-in */
}

.facts-container[style*="visible"] {
  opacity: 1; /* Fully visible */
}

/* Header: Title and Year */
.header {
  display: flex;
  justify-content: space-between; /* Space between title and year */
  align-items: center;
  padding: 10px 0; /* Top and bottom padding */
  border-bottom: 1px solid rgba(255, 255, 255, 0.4); /* Proper syntax for border-bottom */
  margin-bottom: 20px;
  width: 100%; /* Ensure the header spans the entire container width */
  box-sizing: border-box; /* Include padding in width calculations */
}

.title {
  font-size: 1rem;
  font-weight: 700;
  color: var(--earf-blue); /* Custom earf-blue color */
  margin: 0;
  flex: 1; /* Allow title to take up available space */
}

.year {
  font-size: 0.8rem;
  font-weight: 600;
  color: white; /* White color for the year */
  display: flex;
  align-items: center; /* Align text and icon vertically */
  gap: 5px; /* Add space between the icon and year */
  white-space: nowrap; /* Prevent text wrapping */
}

.icon {
  font-size: 1.2rem; /* Match the size of the text */
  color: var(--earf-blue); /* Use earf-blue for the icon */
}

/* Fact Text */
.text {
  font-size: 0.9rem; /* Slightly larger font size for readability */
  line-height: 1.6;
  color: white;
  margin-top: 15px; /* Add spacing between fact and header */
}

/* Style links in the facts container */
.facts-container a {
  color: var(--earf-blue); /* Use the custom earf-blue color */
  text-decoration: none; /* Remove the underline */
  font-weight: 500; /* Optional: Make the links bold */
  cursor: pointer; /* Ensure the pointer cursor is shown */
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.facts-container a:hover {
  color: #0077cc; /* Change to a slightly different color on hover */
}

/* Error Message */
.error {
  color: red;
  font-weight: bold;
  text-align: center;
}

/* Loading Text */
.loading {
  color: white;
  font-style: italic;
  margin-top: 10px;
  text-align: center;
}

/* Fact Block */
.fact {
  margin-bottom: 1.5rem;
  z-index: 2; /* Ensure content is above the loader when visible */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .facts-container {
    max-width: 90%; /* Use a percentage for better responsiveness */
    padding: 15px;
    margin-top: 200px; /* Adjust margin for smaller screens */
  }

  .header {
    padding: 8px 0; /* Reduce padding for smaller screens */
  }

  .title {
    font-size: 0.9rem;
  }

  .year {
    font-size: 0.7rem;
    gap: 3px;
  }

  .icon {
    font-size: 1rem;
  }

  .text {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .loading {
    font-size: 0.8rem;
  }
}
