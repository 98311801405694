/* Content Wrapper Styling */
.content-wrapper {
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--content-background);
}

/* Header Section for Radio Stations and Browse */
.radio-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--border-color);
  width: 100%;
  color: #ffffff;
  flex-wrap: wrap;
  
}



/* Radio Title Styling */
.radio-title {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  margin-left: 20px;
  min-width: 150px;
  vertical-align: middle; /* Ensures it stays aligned with the text */

}

/* Scrollable container for the station list with hidden scrollbars */
.scrollable-content {
  max-height: calc(100vh - 140px); /* Adjust as necessary to account for header height and padding */
  overflow-y: scroll; /* Enable scrolling vertically */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  width: 100%;
}

/* Hide scrollbar for WebKit browsers */
.scrollable-content::-webkit-scrollbar {
  display: none; /* Completely hide the scrollbar */
}

/* Station List Styling */
.station-list {
  max-height: calc(100vh - 50px);
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 15px;
  justify-items: start;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
}

/* Individual Station Item Styles */
.radio-station-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 200px;
  min-height: 220px; /* Min height for consistency */
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.281);

}

.radio-station-item:hover {
  transform: scale(1.02);
}

.radio-station-item::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: var(--secondary-text-color);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.radio-station-item:hover::after {
  transform: scaleX(1);
}

/* Thumbnail Image Styling */
.radio-station-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: transform 0.3s ease;
}

.radio-station-item:hover img {
  transform: scale(1.05);
}

/* Text Backdrop for Station Names */
.radio-station-item .text-backdrop {
  padding: 20px;
  text-align: left;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  
}



/* Load More Target Element */
.load-more-target {
  height: 1px;
  width: 100%;
  margin-top: 10px;
}

/* Image styles with lazy loading and blur */
.favicon-icon {
  width: 50px;
  height: 50px;
  transition: filter 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.favicon-icon.loading {
  filter: blur(10px); /* Apply blur while loading */
  opacity: 0.5;
}

.favicon-icon.loaded {
  filter: blur(0); /* Remove blur when loaded */
  opacity: 1; /* Fade in */
}

/* Placeholder for loading image (blurred) */
.image-placeholder {
  width: 50px;
  height: 50px;
  background-color: #f0f0f0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  filter: blur(10px); /* Same blur effect */
}

/* Wrapper for image */
.feature-thumbnail {
  position: relative;
  width: 50px;
  height: 50px;
}
.station-tags {
  margin-top: 10px;
  display: flex; /* Use Flexbox for better control of spacing */
  flex-wrap: wrap; /* Allow tags to wrap to the next line if needed */
  gap: 8px; /* Add space between tags */
}

.station-tag {
  background-color: var(--components);

  color: var(--text-color);
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  display: inline-block; /* Ensure tags are displayed inline */
}

.station-tag:hover {
  color: white;
}


/* Responsive Adjustments for Medium Devices (max-width: 768px) */
@media (max-width: 768px) {


  .radio-title {
    margin: 10px 0;
    text-align: center;
    font-size: 20px; /* Reduced size for better fit */
  }

  .back-button {
    margin-left: 0;
    margin-bottom: 10px;
    width: 95%;
  }

  .search-input {
    width: 100%;
    margin-bottom: 10px;
    max-width: 200px; /* Increase width for better usability */
    margin-right: 0;
  }

  .station-list {
    grid-template-columns: repeat(auto-fit, minmax(150px, 2fr)); /* Reduce item size to fit more */

  }

  .radio-station-item {
    width: 100%;
    min-height: 130px; /* Further reduce min-height */
  }
  .station-list {
    gap: 20px; /* Reduce the gap to better fit items on small screens */
  }

  .radio-title {
    font-size: 18px; /* Further reduce for very small screens */
    margin-bottom: 10px;
  }
}

/* Spinner Animation Keyframes */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
