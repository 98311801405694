/* Minimal Audio Player in Header */
.audio-player-header {
  display: flex;
  align-items: center;
  background: rgba(104, 223, 223, 0.133);
  color: #fff;
  padding: 5px 10px;
  gap: 10px;
  max-width: 225px;
  border-radius: 10px;
}

.favicon-icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.station-info-header {
  flex-grow: 1;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden; /* Ensures text doesn't overflow its container */
  position: relative;
  max-width: 150px;
}

/* Scrolling text effect */
.station-info-header span {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-text 5s linear infinite; /* Scroll continuously */
}

.station-info-header span.scroll {
  animation: scroll-text 5s linear infinite; /* Ensure scroll only if needed */
}

/* Smooth and continuous right-to-left scroll */
@keyframes scroll-text {
  0% {
    transform: translateX(100%); /* Start just off the right side of the container */
  }
  100% {
    transform: translateX(-100%); /* Scroll all the way to the left */
  }
}

/* Play/Pause and Stop Icons as Buttons */
.play-pause-button,
.stop-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: color 0.3s ease;
}

.play-pause-button:hover,
.stop-button:hover {
  color: rgba(104, 223, 223, 0.733);
}

.audio-player-header button {
  margin-left: 5px;
}
/* Mobile Responsive */
@media (max-width: 768px) {
  .audio-player-header {
    max-width: 150px;
    padding: 5px;
  }
  
  .favicon-icon {
    width: 25px;
    height: 25px;
  }

  .station-info-header {
    font-size: 12px;
    max-width: 120px; /* Reduced width for mobile */
  }

  .play-pause-button,
  .stop-button {
    font-size: 18px;
  }
}

/* Icon in header */
.favicon-icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

/* Station info with scrolling text */
.station-info-header {
  flex-grow: 1;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  max-width: 150px;
}

@media (max-width: 600px) {
  .station-info-header {
    font-size: 12px; /* Smaller text for mobile */
    max-width: 120px; /* More compact on mobile */
  }
}

/* Scrolling text effect */
.station-info-header span {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-text 5s linear infinite;
}

.station-info-header span.scroll {
  animation: scroll-text 5s linear infinite;
}

/* Smooth and continuous right-to-left scroll */
@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Play/Pause and Stop Buttons */
.play-pause-button,
.stop-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: color 0.3s ease;
}

.play-pause-button:hover,
.stop-button:hover {
  color: rgba(104, 223, 223, 0.733);
}

.audio-player-header button {
  margin-left: 5px;
}

@media (max-width: 600px) {
  .play-pause-button,
  .stop-button {
    font-size: 18px; /* Slightly smaller icons for mobile */
  }
}