.fbi-logo {
    width: 40px;
    vertical-align: middle;
  }
  .fbi-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .fbi-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc(100vh - 270px); /* Subtract any other heights like header/footer */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    padding-right: 10px; /* Optional: Add space for scrollbar */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

/* For Chrome, Safari, and Edge */
.fbi-list::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
}
  
  .fbi-card {
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .fbi-card:hover {
    background-color: var(--hover-background); /* Slight hover effect */
  }
  
  .fbi-card-content {
    display: flex;
    align-items: center;
    width: 100%; /* Ensure it stretches the entire width */
  }
  
  .fbi-thumbnail {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 15px;
  }
  
  .fbi-info {
    flex: 1;
  }
  
  .fbi-name {
    font-size: 16px;
    color: var(--earf-blue);
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .fbi-short-description {
    font-size: 13px;
    color: #ccc;
    margin-bottom: 5px;
  }
  

  .fbi-details {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    padding: 20px;
    background-color: #111;
    border-radius: 10px;
    height: 100%; /* Allow it to grow fully */
  }
  
  
  .fbi-left {
    display: flex;
    flex-direction: column;
  }
  
  .fbi-details-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fbi-warning {
    color: red;
    margin-bottom: 10px;
  }
  
  .fbi-details-image {
    width: 180px;
    height: 200px;
    border-radius: 10px;
  }
  .fbi-details-image:hover {
    transform: scale(1.3); /* Slightly enlarge the item */
  }
  .fbi-more-info-button {
    margin-top: 10px;
    background-color: var(--primary-background);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    text-decoration: none; /* Remove underline */
  }
  
  .fbi-more-info-button:hover {
    background-color: var(--hover-background); /* Change background on hover */
    color: var(--earf-blue); /* Change text to earf-blue on hover */
    text-decoration: none; /* Ensure no underline on hover */
}
  
  .fbi-more-info-button img {
    width: 20px;
    margin-right: 8px;
  }
  
  .fbi-details-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .fbi-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fbi-title {
    font-size: 22px;
    color: var(--earf-blue);
    margin: 0;
  }
  
  .fbi-back-to-list-button {
    background-color: var(--primary-background);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
  }
  
  .fbi-back-to-list-button:hover {
    background-color: var(--hover-background);
  }
  
  .fbi-details-info {
    margin-top: 10px;
  }
  
  .fbi-details-info p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .fbi-link {
    display: inline-block;
    margin-top: 10px;
    color: var(--earf-blue);
    text-decoration: underline;
    font-size: 14px;
  }

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  
  .fbi-name {
    font-size: 14px;
  }
  .fbi-header h2 {
font-size: 14px;
  }
}
