/* ServerStatus.module.css */
.status-container {
  position: absolute;
  top: 10px;
  left: 20px;
  padding: 10px;
  background-color: #141517;
  border-radius: 8px;
  color: white;
}

.status-row {
  display: flex;
  align-items: center; /* Align circle and text in one row */
}

.green-circle, .red-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.green-circle {
  background-color: #4CAF50;
}

.red-circle {
  background-color: #ff4d4d;
}

.status-text {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}

.server-url {
  font-size: 10px;
  color: #cfd1d4; 
  margin-left: 5px;
}

.server-details {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.6;
}

.server-details p {
  margin: 0;
  color: #cfd1d4;
}

.server-details p:nth-child(2) {
  margin-top: 5px;
}
.server-url {
  font-size: 10px;
  color: #cfd1d4; 
  margin-left: 5px;
  cursor: pointer;
}

.url-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Keep the same color as the surrounding text */
}

.url-link:hover {
  cursor: pointer;
}
/* Media queries for mobile responsiveness */

/* For devices with a max width of 768px (tablets, phones) */
@media (max-width: 768px) {
  .status-container {
    position: static; /* Remove absolute positioning */
    width: 50%; /* Take up most of the screen width */
    max-width: 300px;
  }

  .status-text {
    font-size: 14px; /* Reduce font size */
  }

  .server-details {
    font-size: 12px; /* Reduce font size for details */
  }

  .green-circle, .red-circle {
    width: 10px; /* Smaller status circles */
    height: 10px;
  }
}

/* For very small devices with a max width of 480px (phones) */
@media (max-width: 480px) {
  .status-container {
    width: 100%; /* Take up full screen width */
    padding: 10px; /* Less padding for very small screens */
  }

  .status-text {
    font-size: 12px; /* Further reduce text size */
  }

  .server-details {
    font-size: 11px; /* Smaller text size for server details */
  }

  .green-circle, .red-circle {
    width: 8px; /* Even smaller circles */
    height: 8px;
  }
}