
/* Container styling */
.camera-request-container {
  max-width: 70%;
  padding: 15px;
  margin: 0 auto;
  border-radius: 8px;
  color: white;
  box-sizing: border-box;
  max-height: 100vh; /* Ensure the container height doesn't exceed the viewport */
  overflow-y: auto;  /* Allow the entire container to scroll if it's too tall */
  background-color: var(--content-background);

}

/* Header styling */
.camera-request-header {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: white;
}

/* Flexbox for side-by-side layout */
.camera-request-form {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  box-sizing: border-box;
  /* Remove individual scroll */
}

/* Form fields (input, select, textarea) */
.camera-request-form input,
.camera-request-form select,
.camera-request-form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: white;
  background-color: #1e1e1e;
  box-sizing: border-box;
}

/* Flex items: Two columns on larger screens */
.camera-request-form .form-group {
  flex: 1 1 calc(50% - 20px);
  min-width: 200px;
  box-sizing: border-box;
}

/* Full-width fields (for textarea or other wide inputs) */
.camera-request-form .full-width {
  flex: 1 1 100%;
}

/* Add focus effect to form fields */
.camera-request-form input:focus,
.camera-request-form select:focus,
.camera-request-form textarea:focus {
  outline: none;
  box-shadow: 0 0 5px var(--secondary-text-color);
}

/* Style the overall autocomplete container */
.pac-container {
  background-color: #1e1e1e;
  border: none;
  z-index: 10000;
  color: white;
}

/* Style individual items */
.pac-item {
  background-color: #1e1e1e;
  border: none;
  margin: 0;
  color: white;
  box-shadow: none;
}

/* Style hovered item */
.pac-item:hover {
  background-color: var(--hover-background);
  cursor: pointer;
  color: white;
}

/* Style the highlighted text in results */
.pac-item .pac-item-query {
  font-weight: bold;
  color: white;
  border: none;
}

/* Style the textarea specifically */
.camera-request-form textarea {
  resize: vertical;
  min-height: 120px;
}

.suggestions-list {
  list-style-type: none;
  background: var(--content-background);
  margin: 0;
  padding: 0;
  max-height: 300px;
  max-width: 50%;
  overflow-y: auto;
  position: absolute;
  width: calc(100% - 24px);
  z-index: 1000;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

/* Submit button styling */
.camera-request-form button {
  width: 20%;
  padding: 15px;
  background-color: var(--secondary-text-color);
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect for submit button */
.camera-request-form button:hover {
  background-color: #59a1c2;
}

.form-button {
  align-items: right;
  justify-content: right;
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .camera-request-form .form-group {
    flex: 1 1 100%;
  }
  
  .camera-request-header {
    font-size: 20px;
  }

  .camera-request-container {
    max-height: 95vh; /* Limit the height for smaller screens */
    min-width:  100%;
    overflow-y: auto;  /* Ensure the container can scroll on mobile */
  }

  .camera-request-form input,
  .camera-request-form select,
  .camera-request-form textarea {
    padding: 10px;
    font-size: 14px;
  }
/* Submit button styling */
.camera-request-form button {
  width: 100%;

}

/* Hover effect for submit button */
.camera-request-form button:hover {
  background-color: #59a1c2;
}

.form-button {
  align-items: right;
  justify-content: center;
  display: flex;
}
  .camera-request-form button {
    font-size: 16px;
  }
}
