.place-info-bar {
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 14px; /* Keep text small for a compact look */
    white-space: nowrap; /* Ensure everything stays in one line */
    padding: 0;
    margin: 0;
    margin-top: 10px;
    font-weight: 600;

  }
  
  .place-info-item {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Minimal space between items */
  }
  
  .weather-icon {
    width: 30px;
    height: 30px;
    margin-right: 5px; /* Small space between icon and weather text */
    vertical-align: middle; /* Ensure icon is vertically aligned with the text */
  }
  
  img {
    width: 30px;
    height: 30px;
    vertical-align: middle; /* Ensure all images (icons) are vertically aligned */
  }
  
  @media (max-width: 768px) {
    .place-info-bar {
      font-size: 10px; /* Slightly smaller text on mobile */
      max-width: 95%;
    }
  }
  