.live-alerts-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: white;
  background-color: var(--content-background);
  height: 100%;
}

.live-alerts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Ensure header elements don't overflow on small screens */
}

.pagination-buttons {
  display: flex;
  gap: 10px;
}

.prev-page-button, .next-page-button {
  background-color: var(--primary-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 14px; /* Smaller font size for mobile */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.prev-page-button:disabled {
  background-color: #555; /* Change color when disabled */
  cursor: not-allowed;
}

.prev-page-button:hover, .next-page-button:hover {
  background-color: var(--hover-background);
}

.live-alerts-header h1 {
  margin: 0;
  font-size: 24px; /* Adjust font size for better readability on smaller screens */
  color: white;
}

.earf-blue {
  color: var(--earf-blue);
}

.live-alerts-tabs {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap; /* Tabs will wrap to new lines on small screens */
}

.live-alerts-tabs button {
  padding: 8px 15px; /* Smaller padding for mobile */
  background: none;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 14px; /* Reduce font size for mobile */
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.live-alerts-tabs button:hover {
  color: var(--earf-blue);
}

.live-alerts-tabs button.active-tab {
  color: var(--earf-blue);
  font-weight: bold;
}

.live-alerts-content {
  margin-top: 15px;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {


  .live-alerts-header h1 {
    font-size: 16px; /* Further reduce header size on smaller screens */
  }

  .pagination-buttons {
    gap: 5px;
  }
  .header-container h2 {
    font-size: 16px;
   }
  .prev-page-button, .next-page-button {
    padding: 6px 10px;
    font-size: 12px; /* Reduce button size and padding for smaller screens */
  }

  .live-alerts-tabs button {
    padding: 6px 10px;
    font-size: 12px; /* Smaller tab buttons on mobile */
  }
}
