.infocontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  border-bottom: 1px solid #5a5a5aa8;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Aligns the header content and buttons vertically */
}

.headerContent {
  display: flex;
  flex-direction: column; /* Stack flag, text, and motto vertically */
  align-items: flex-start; /* Align all items to the left */
  gap: 5px; /* Small gap between the flag-text row and the motto */
  margin: 0; /* Remove any external margins */
  padding: 0; /* Remove any padding */
  color: white;
  font-size: 1rem;
}

.headerRow {
  display: flex;
  align-items: center; /* Aligns flag and text inline */
  gap: 10px; /* Spacing between flag and text */
  width: 100%;
}

.flag {
  width: 50px;
  height: 30px;
  border-radius: 4px;
}

.motto {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--earf-blue); /* Adjust to your preferred style */
  font-style: italic;
}

.buttons {
  display: flex;
  gap: 15px;
  margin-right: 20px;
  justify-content: flex-end; /* Align buttons to the right */
  align-items: center; /* Vertically center the buttons */
}

.liveText {
  color: var(--earf-blue);
}

.centeredcontainer {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  max-height: 300px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.descriptionloader {
  width: 35px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #68dfdfbb 90%, #0000) 0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
      clip-path: inset(0 -34% 0 0);
  }
}

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}
.alert {
  display: flex;
  align-items: center;
  color: #ff4d4f; /* Bright red text for alert */
  font-size: 14px;
  background-color: var(--content-background); /* Optional background for the alert text */font-weight: 600;
}

.alertIcon {
  font-size: 25px;
  margin-right: 10px;
  animation: flash 2s infinite; /* Flashing animation for the icon */
}

/* Keyframes for flashing icon */
@keyframes flash {
  0% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0.3; /* Dimmed */
  }
  100% {
    opacity: 1; /* Fully visible again */
  }
}

.tooltip {
  position: absolute;
  bottom: -30px; /* Tooltip appears below the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8); /* Dark background for contrast */
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 10;
  pointer-events: none;
}

.iconButton:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.iconButton img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.iconButton:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.description {
  font-size: 0.9rem;
  color: #e0e0e0;
  padding-bottom: 10px;
}

.tags {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

button.iconButton {
  all: unset; /* Reset all button styles */
  display: inline-flex; /* Reapply display */
  align-items: center; /* Vertically center content */
  justify-content: center;
  cursor: pointer;
}
