/* Container for video view */
.video-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row; /* Display main content and sidebar side by side */
    align-items: flex-start;
    margin: 0 auto;

  }
  
  /* Video Info Container (Video and Info) */
  .video-info-container {
    flex: 0 0 80%; /* Occupies 70% of the width */
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    box-sizing: border-box;
    background-color: aqua !important;
  }


  /* Video container styling */
  .video-container {
    width: 100%;
    height: calc(100% - 150px); /* Deduct space for video info */
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
  
  .video-container iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  .main-video-content {
    flex-grow: 1;
    height: 100%;
    transition: all 0.3s ease;
    margin: 0 auto;
    padding: 0;
    position: relative;
    overflow-y: scroll; /* Enable scrolling */
    width: 95%;
  }
  
  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  .main-video-content::-webkit-scrollbar {
    display: none;
  }
  .wikidataText {
    font-size: 14px;
    font-weight: 600;
  }
  
  /* Hide scrollbar for Firefox */
  .main-video-content {
    scrollbar-width: none; /* Firefox */
    background-color: var(--content-background);

  }
  
  /* Optional: Hide scrollbar for Edge, IE */
  .main-video-content {
    -ms-overflow-style: none; /* IE and Edge */
  }
  

/* Video info container */
.video-info {
    width: 98%;
    box-sizing: border-box;
    color: white;
    z-index: 10;
    /* Ensure it stays above the video */
    margin-top: 5px;
    margin-left: 15px;

}

/* Channel info styling */
.channel-info {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}

.channel-info .back-button {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 10px 10px;
    font-size: 16px;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.mini-player {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    height: 170px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mini-player-container {
    position: relative;
    width: 100%;
    height: 100%;

}

.mini-player-video {
    width: 100%;
    height: 100%;
}

.mini-player-stop {
    position: absolute;
    top: 0px;
    right: 0px;
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.mini-player-enlarge {
    position: absolute;
    top: 0px;
    right: 50px;
    background: rgb(154, 214, 147);
    border: none;
    padding: 5px;
    padding-left: 10px;
    color: black;
    font-size: 13px;
    cursor: pointer;
}

.mini-player-enlarge i {
    margin-right: 5px;
}

#channel-thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 8px;
}

.channel-details {
    flex-grow: 1;
}

.channel-info h4 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}


.channel-info a {
    color: var(--secondary-text-color);
    text-decoration: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.channel-info a:hover {
    text-decoration: none;
}

.feature-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0;
}

.feature-tag {
    background-color: var(--components);
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
}

.feature-tag:hover {
    color: var(--earf-blue);
}


.video-info p {
    margin: 0;
    text-align: left;
    color: white;
    width: 100%;
}

.video-info h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: white;
    width: 100%;

}

/* Explicitly target the website link within the channel-info */
.channel-info .website-link {
    color: rgb(155, 155, 155) !important;
    /* Enforce the light grey color */
    font-size: 16px;
    /* Adjust the size if necessary */
}

/* Hover effect for the website link */
.channel-info .website-link:hover {
    color: var(--secondary-text-color) !important;
    /* Change on hover */
    text-decoration: none !important;
    /* Ensure no underline */
}
.info-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
  }
  
  .info-left {
    flex: 1;
    
  }
  

  .info-right {
    display: flex;
    flex-direction: row; /* Keeps LocationInfo and back button in a row */
    gap: 10px; /* Space between LocationInfo and back button */
  }
  
  .location-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align components to the right */
    gap: 8px; /* Add spacing between components */
  }
  
  .navigate-to-map-container {
    width: 100%; /* Ensures the button respects the layout */
    text-align: right; /* Aligns the button to the right */
  }
  
  .navigate-to-map-button {
    background-color: var(--secondary-background); /* Subtle dark background */
    color: #ccc; /* Light gray text for dark themes */
    border: none; /* Slight border to distinguish the button */
    padding: 8px 12px; /* Padding for a smaller, less standout button */
    border-radius: 4px; /* Rounded corners for a modern look */
    font-size: 0.9rem; /* Slightly smaller font */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .navigate-to-map-button:hover {
    background-color: #444; /* Slightly brighter hover effect */
    color: #fff; /* Change text to white on hover */
  }
  
/* Mobile-specific adjustments */
@media (max-width: 768px) {

    /* Video view container */
    .video-view {
        height: 100%;
        width: 100%;
        /* Allow height to adjust based on content */
        padding-bottom: 10px;
        flex-direction: column; /* Display main content and sidebar side by side */
overflow-y: scroll;
    }

    /* Video container styling */
    .video-container {
        height: auto;
        /* Allow video container to adjust height dynamically */
        padding: 5px;
        margin-top: 10px;
        margin-left: 0px;
        min-width: 100%;

    }

    .video-container iframe {
        width: 100%;
        aspect-ratio: 16/9;
        /* Maintain a 16:9 aspect ratio for the video */
        border-radius: 8px;
    }

    /* Video info container for mobile */
    .video-info {
        padding-top: 10px;
        box-sizing: border-box;
        color: white;
        margin-left: 0px;
        min-width: 100%;

    }

    /* Channel info adjustments for mobile */
    #channel-thumbnail {
        width: 80px;
        /* Reduce thumbnail size for smaller screens */
        height: 80px;
        margin-right: 10px;
    }

    .channel-info {
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 15px;
    }


    .channel-details h4 {
        font-size: 14px;
    }

    .feature-tags {
        gap: 5px;
        /* Adjust spacing between tags on mobile */
    }

    .feature-tag {
        font-size: 11px;
        padding: 4px 8px;
    }

    .mobile-back-button {
        width: 100%;
        /* Make the back button 90% width */
        margin: 0 auto;
        margin-top: 10px;
        /* Add margin at the top */
        padding: 10px;
        /* Padding around the button */
        font-size: 14px;
        background-color: var(--secondary-background);
        /* Customize background */
        color: white;
        /* Customize text color */
        border: none;
        /* No border */
        cursor: pointer;
        text-align: center;
        /* Center the text inside the button */
        align-self: center;
 
    }
    .info-container {
        flex-direction: column;
        align-items: flex-start;
      }
    
      .info-right {
        justify-content: flex-start;
        width: 100%;
      }
      .video-view {
          flex-direction: column; /* Stack elements on small screens */
          width: 100%;
        }
        .video-info-container,
        .similar-videos-sidebar {
          flex: 1 1 100%; /* Full width on mobile */
        }
}