.mobile-sidebar {
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 100%;
    height: calc(100% - 60px);
    background-color: var(--primary-background);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    padding: 10px;


}

.mobile-sidebar.open {
    transform: translate(-50%, 0);
}

.mobile-sidebar.collapsed {
    transform: translate(-50%, -100%);
}

.mobile-sidebar .section-divider {
    width: calc(100% - 10px);
}
.sidebar {
    position: fixed;
    top: 64px;
    left: 0;
    height: calc(100% - 64px);
    width: 250px;
    background-color: var(--primary-background);
    overflow-y: auto;
    transition: width 0.3s ease;
    z-index: 999;
    padding: 5px;
  }
  
  .sidebar.collapsed {
    width: 40px;
    text-align: center;
  }
  .country-item,
  .sidebar-menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--text-color);
    font-size: 16px;
    text-decoration: none; /* Remove underline */
    font-weight: 600 !important;
  
  }
  
  .sidebar-menu-item span {
    margin-left: 10px;
  }
  
  .sidebar.collapsed .sidebar-menu-item {
    justify-content: center;
  }
  
  .sidebar.collapsed .sidebar-menu-item span {
    display: none;
  }
  
  .sidebar-menu-item:hover {
    background-color: var(--hover-background);
  }
  
  .sidebar-items {
    margin-top: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
  
  }
  
  .section-divider {
    border: none;
    height: 2px;
    background-color: #333;
    margin: 10px auto;
  }
  
  
  
  .popular-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-section {
    color: rgb(223, 223, 223);
    font-size: 10px;
    margin-left: 10px;
  }
  
  .collapsed-header {
    display: none;
    font-size: 20px;
    color: var(--secondary-text-color);
    text-align: center;
    width: 100%;
  }
  
  .sidebar.collapsed .collapsed-header {
    display: flex;
    justify-content: center;
    margin-right: 10px;
    align-items: center;
  }
  
  .sidebar-items {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    transition: max-height 0.3s ease;
  }
  
  .sidebar-items::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  
  .sidebar-items {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  
  
  
  .country-item:hover {
    background-color: var(--hover-background);
  }
  
  .flag-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .browsetext-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    white-space: nowrap;
    overflow: hidden;
    opacity: 1;
    visibility: visible;
    width: auto;
    transition: width 0.5s ease, opacity 0.5s ease;
  }
  
  .country-name,
  .region-name {
    margin: 0;
    padding: 0;
    text-transform: none;
    line-height: 1;
    font-weight: 600 !important;
  
  
  }
  
  .country-name {
    font-size: 14px;
    color: var(--text-color);
    letter-spacing: 0.5px;
    font-weight: 500;
    transition: font-size 0.3s;
  }
  
  .region-name {
    font-size: 13px;
    color: #ccc;
    font-weight: 500;
  }
  
  
  .sidebar.collapsed .browsetext-container {
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: width 0.5s ease, opacity 0.5s ease, visibility 0s 0.5s;
    display: none;
  
  }
  
  
  
  
  .sidebar.collapsed .country-item {
    justify-content: center;
    max-width: 50px;
  
  }
  
  
  
  .sidebar.collapsed .flag-icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: 10px;
  
  }


@media screen and (min-width: 769px) {
    .sidebar-menu-item {
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 16px;
        color: var(--text-color);
        font-weight: 600;
        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.3s ease;

    }

    .sidebar-menu-item:hover {
        background-color: var(--hover-background);
    }

    .sidebar-menu-item span {
        margin-left: 10px;
    }

    .section-divider {
        border: none;
        height: 2px;
        background-color: #333;
        margin: 10px 0;
    }

    .popular-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .left-section {
        font-size: 10px;
        color: rgb(223, 223, 223);
    }

    .collapsed-header {
        display: none;
    }

    .collapsed-header.show {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin-top: 10px;
        color: var(--secondary-text-color);
    }

    .sidebar-items::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .sidebar-items {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

}