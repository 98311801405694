/* Footer Container */
.footer-container {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}

/* Logo */
.footer-logo {
    margin-bottom: 10px;
}

.footer-logo img {
    width: 50px; /* Adjust size accordingly */
}
.footer-container img {
    width: 50px; /* Adjust size accordingly */
    height: auto;
}
/* Footer Links */
.footer-links {
    justify-content: center;
    gap: 50px; /* Add horizontal space between sections */
    margin-bottom: 10px; /* Add spacing below the links */
    flex-wrap: wrap; /* Wrap on smaller screens */
}

.footer-section h4 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
    color: #bbb;
    margin: 0;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    display: inline;
    position: relative;
    margin-right: 15px;
}

.footer-section ul li:not(:last-child)::after {
    content: "|"; /* Adds the vertical pipe separator */
    color: #666;   /* Adjust color to match the style */
    margin-left: 10px; /* Space between the text and pipe */
}

.footer-section ul li a {
    color: #ccc;
    text-decoration: none;
    font-size: 14px;
}

.footer-section ul li a:hover {
    color: var(--earf-blue);
}
.footer-section ul li span {
    font-weight: bold;
    font-size: 14px;
    color: #a7a6a6; /* Text color for the label */
}


/* Language Selection */
.footer-language {
    margin-bottom: 20px;
}

.language-select {
    background-color: #333;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.fancy-title-span {
    font-size: 24px;
    font-weight: bold;
    color: var(--earf-blue);
  }
  
  .fancy-title-dot {
    font-size: 24px;
    font-weight: bold;
    color: #34ebdf;
  }
  
/* Social Media Icons */
.footer-social {
    display: flex;
    justify-content: center;
    gap: 30px; /* Adjust gap between icons */
}

.footer-social a {
    color: #ccc;
    font-size: 24px;
    transition: color 0.2s, transform 0.2s;
}

.footer-social a:hover {
    color: var(--earf-blue);
    transform: scale(1.1);
}
