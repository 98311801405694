.featureTags {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.inlineContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  /* Adjusts spacing between items */
}

.flagContainer {
  display: inline-block;
  width: 40px;
  height: 25px;
  border-radius: 5px;
  /* Ensure the flag is rectangular */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.flag {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Keeps the flag rectangular */
}

.statsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 20px;
}

.statTag {
  background-color: #2d2d30;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
}