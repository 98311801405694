/* Container for entire browse view */
.browse-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    background-color: var(--content-background);

}

/* Container for tag view and feature view */
.tag-view,
.feature-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 60px);
}

.tag-list-container,
.feature-list-container {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Optional styling to add some margin and make scrolling smoother */
.tag-list,
.feature-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* Hide the scrollbar for WebKit browsers (Chrome, Safari) */
.tag-list-container::-webkit-scrollbar,
.feature-list-container::-webkit-scrollbar {
    display: none;

}

.tag-list-container::-webkit-scrollbar-thumb,
.feature-list-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}


.tag-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Reduced gap between rows */
    box-sizing: border-box;
    color: var(--text-color);
    font-weight: 500 !important;
}

.header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: -20px; /* Ensure no extra margin */
}

.description-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px; /* Tighten spacing */
}

.browse-description {
    margin: 0;
    font-size: 1rem;
    color: var(--text-color);
    line-height: 1.2; /* Reduced line height for tighter spacing */
    max-width: 85%; /* Slight adjustment for better alignment */
    margin-left: 10px;
}

.filter-toggle {
    display: flex;
    align-items: center;
    gap: .1rem;
}

.toggle-text {
    cursor: pointer;
    font-size: 0.9rem;
}

.toggle-text.active {
    font-weight: bold;
    text-decoration: none;
    font-size: 1rem;
}

.sort-dropdown {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    border: none;
    justify-content: flex-end; /* Ensure dropdown stays on the right */
    margin-bottom: 10px; /* Tighten spacing */
    margin-right: 10px;

}

.sort-select {
    padding: 0.3rem 0.6rem;
    font-size: 0.9rem;
    border-radius: 4px;
    border: none;
    background: var(--content-background);
    color: var(--text-color);
}


.feature-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    color: var(--text-color);
    font-weight: 600;
}

.centered-header {
    display: flex;
    text-align: left;
    flex-grow: 1;
    margin-left: 10px;
    display: flex;
    align-items: left;
    font-size: 24px;
    font-weight: 500;

}

.country-flag-title {
    width: 50px;
    /* Smaller flag size */
    max-width: 50px;
    height: 30px;
    /* Keep the aspect ratio */
    max-height: 30px;
    margin-right: 10px;
    /* 5px gap between flag and text */
    vertical-align: middle;
    /* Ensures it stays aligned with the text */
    border-radius: 6px !important;
}


.left-header {
    text-align: left;
    flex-grow: 1;
    margin-left: 10px;
    font-weight: 600;
    font-size: 24px;
}




/* Grid layout for tags and features */
.tag-list,
.feature-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-items: start;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

}

.tag-list::-webkit-scrollbar,
.feature-list::-webkit-scrollbar {
    display: none;
}

/* Tag Item Styling */
.tag-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 300px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 0 auto;
    text-decoration: none;

}

.tag-item:hover {
    transform: scale(1.02);
}

.tag-thumbnail {
    position: relative;
    width: 100%;
    height: 150px;
    overflow: hidden;
}

.tag-thumbnail img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    transition: filter 0.3s ease; /* Smooth transition for blur */
}

.loading {
  filter: blur(10px); /* Apply blur when the image is loading */
}

.loaded {
  filter: none; /* Remove blur once the image has loaded */
}

.image-placeholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0; /* Placeholder color */
  position: absolute;
  top: 0;
  left: 0;
}

.tag-thumbnail {
  position: relative; /* Make the placeholder appear over the image */
}
.tag-info {
    width: 100%;
    padding: 10px;
    margin-left: -8px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

}

.tag-name {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color);
    margin: 0;
    padding: 0;
}

.tag-count {
    font-size: 14px;
    color: var(--secondary-text-color);
}

/* Hover effect */
.tag-item:hover::after {
    transform: scaleX(1);
}

.tag-item::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: var(--secondary-text-color);
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

/* Individual feature item styling */
.feature-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 300px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 0 auto;
 text-decoration: none;


}

/* Individual feature item styling */
.feature-item:hover {
    transform: scale(1.02);
}


.feature-item::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: var(--secondary-text-color);
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

/* Feature thumbnail styling */
.feature-thumbnail {
    position: relative;
    width: 100%;
    height: 150px;
    overflow: hidden;
}

.feature-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: filter 0.3s ease, opacity 0.3s ease; /* Smooth transition */
    opacity: 0; /* Initially hidden */
}

.feature-thumbnail-img.loading {
    filter: blur(20px); /* Apply blur effect during loading */
    opacity: 0.5;
}

.feature-thumbnail-img.loaded {
    filter: blur(0); /* Remove blur once the image is loaded */
    opacity: 1; /* Make the image fully visible */
}

/* Placeholder for loading image (blurred) */
.image-placeholder {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    filter: blur(20px); /* Apply blur effect to the placeholder */
    z-index: 999;
}

.feature-item:hover::after {
    transform: scaleX(1);
}



/* Live badge styling */
.live-badge {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: var(--content-background);
    opacity: 0.95;
    color: var(--secondary-text-color);
    font-size: 14px;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 3px;
}

/* Viewer count badge styling */
.viewers-count {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 3px;
}

/* Feature information styling */
.feature-info {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}


/* Feature details styling next to the channel thumbnail */
.feature-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.feature-name {
    font-size: 16px;
    font-weight: 600 !important; 
    color: var(--text-color);
    margin-bottom: 5px;
}

.live-viewers {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background-color: var(--content-background);
    opacity: 0.95;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 3px 6px;
    border-radius: 3px;
}

/* Tags under the feature card */
.feature-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
}
.channel-thumbnail {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
  }
  
  .channel-name  {
    color: rgb(226, 224, 224);
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    margin-top: 5px;

  }
  
  
  .filter-toggle {
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    margin: 10px 0;
    font-size: 16px;
    padding-left: 10px; /* Add some padding for better alignment */
    margin-bottom: 10px;

}

.toggle-text {
    margin: 0 15px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.87);
    text-decoration: none;
    font-weight: bold;

}

.toggle-text.active {
    font-weight: bold;
    color:var(--earf-blue);
}

.toggle-text:hover {
    text-decoration: none;
    color: var(--earf-blue);

}

  .feature-name {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-color);
    margin: 0; /* Remove margin between name and Wikidata */
  }
  
  .feature-wikidata {
    font-size: 12px;
    color: rgb(211, 207, 207);
    margin: 0; /* No margin at the top or bottom */
  }

.feature-tags span {
    background-color: var(--components);

    color: var(--text-color);
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
}

.feature-item .feature-tag:hover {
    color: white;
    /* Change text color to --earf-blue on hover */
}

/* Adjust the gap for larger screens */
@media (min-width: 1024px) {

    .tag-list,
    .feature-list {
        gap: 10px;
        /* Adjust gap between items for larger screens */
    }
}


/* Styles for smaller mobile devices with screen width 480px or smaller */
@media (max-width: 768px) {
    .feature-list {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        justify-content: center;
        gap: 15px;
        /* A slightly smaller gap to better fit content on mobile */
    }

    .feature-item {
        max-width: 250px;
    }

    .feature-thumbnail {
        height: 140px;
    }

    .tag-list {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 15px;
    }

    .tag-item {
        width: 180px;
        height: 140px;
        /* Reduce height to maintain aspect ratio and save space */
    }

    .feature-item {
        max-width: 100%;
        width: 100%;
    }

    .feature-thumbnail {
        height: 120px;
    }

    .tag-item {
        max-width: 100%;
        /* Ensure it still uses full width */
        width: 100%;
        height: 150px;

    }

    .tag-img {
        position: relative;
        width: 100%;
        height: 155px;
        overflow: hidden;
    }

    .tag-thumbnail img {
        width: 100%;
        height: 100%;
        min-height: 100px;
        object-fit: fill;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        transition: transform 0.3s ease;
    }

    .tag-list {
        grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
        gap: 15px;
    }

}