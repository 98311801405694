/* Main container for the login page */
.admin-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: var(--content-background);

}

/* Logo styling */
.admin-logo {
  width: 150px;
  height: auto;
}

/* Heading styling */
.admin-login-container h1 {
  font-size: 36px;
  color: white;
  /* Welcome will remain white */
  margin-bottom: 0;
}

.admin-login-container .admin-word {
  color: rgba(104, 223, 223, 0.733);
}

.admin-login-container p {
  font-size: 18px;
  color: #c5c5c5;
}

/* AdminLogin.module.css */
.error-message {
  color: red !important;
  font-weight: 600 !important;
  font-size: 13px !important;

}

/* Login form styling */
.admin-login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

/* Input fields styling */
.admin-input {
  padding: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #3b3b3b;
  color: white;
}

/* Button container */
.admin-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Login button */
.login-button {
  background-color: rgba(104, 223, 223, 0.733);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: rgba(84, 179, 179, 0.8);
}



/* Adjust button positioning */
.admin-buttons {
  display: flex;
  justify-content: space-between;
}

.admin-buttons .login-button {
  margin-left: auto;
}

.admin-buttons .back-button {
  margin-right: auto;
}

/* Input focus effect for better visibility */
.admin-input:focus {
  outline: none;
  border-color: rgba(104, 223, 223, 0.733);
  background-color: #444444;
}
/* Warning message */
.warning-message p {
  margin-left: 10px;
  font-size: 12px;
  color: #ff9800 !important; /* Add !important to ensure the color is applied */
  text-align: center;
}
