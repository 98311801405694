:root {
  --primary-background:  #0e0e10fb;
  --hover-background: #313538;
  --hover-background-opacity: #313538bd;
  --content-background: #0e0e10;
  --secondary-background: #313538;
  --dashboard-background: rgba(14, 14, 16, 0.6);
  --text-color: #ffffff;
  --secondary-text-color: #68dfdfbb;
  --earf-blue: #68dfdfbb;
  --button-color: #252525;
  --button-hover-color: #313538bd;
  --components:#18181b;
}

.app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

.body-layout {
  display: flex;
  flex: 1;
  height: calc(100vh - 64px);
  margin: 0;
}

.main-content {
  flex-grow: 1;
  height: 100%;
  transition: all 0.3s ease;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.earf-blue {
  color: var(--earf-blue);
  /* Replace with your desired color variable or value */
  font-weight: bold;
  /* Optional, if you want it bold */
}
.full-container {
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  background-color: var(--content-background); /* Use the custom background color */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  gap: 20px; /* Optional: Add spacing between items */
}

/* Spinner Wrapper - Center the Spinner within Parent Container */
.spinner-wrapper {
  position: absolute;
  /* Absolute within the parent container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  
  /* Add a z-index to ensure the spinner is on top of other content if necessary */
}

/* HTML: <div class="spinner"></div> */
.spinner {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 3px;
  background:
    radial-gradient(farthest-side, rgba(104, 223, 223, 0.733) 95%, #0000) 50% 0/12px 12px no-repeat,
    radial-gradient(farthest-side, #0000 calc(100% - 5px), rgba(104, 223, 223, 0.3)calc(100% - 4px)) content-box;
  animation: l6 2s infinite;
}

@keyframes l6 {
  to {
    transform: rotate(1turn)
  }
}

/* Base style for all screen sizes */
.search-input {
  background-color: #1b1b1b;
  border: 1px solid #5555554f;
  border-radius: 5px;
  padding: 8px 12px;
  color: white;
  font-size: 13px;
  min-width: 200px;
  transition: width 0.3s ease-in-out;
  margin-right: 10px;
  width: 200px; /* Default width */
}

/* Expanding the width by 50px on focus */
.search-input:focus {
  outline: none;
  border-color: var(--secondary-text-color);
  width: calc(200px + 50px); /* Expands by 50px */
}

/* For smaller screens (max width 768px), make the input smaller */
@media (max-width: 768px) {
  .search-input {
    width: 150px; /* Smaller default width for smaller screens */
    min-width: 150px;
  }

  .search-input:focus {
    width: calc(150px + 50px); /* Expands by 50px when focused */
  }
}

/* For very small screens (max width 480px), adjust the input width further */
@media (max-width: 480px) {
  .search-input {
    width: 120px; /* Smaller default width for very small screens */
    min-width: 120px;
  }

  .search-input:focus {
    width: calc(120px + 50px); /* Expands by 50px when focused */
  }
}



/* Back Button Styling */
.back-button {
  background-color: #18181b;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: 600;
  width: 100px;
  letter-spacing: 1px;
}

.back-button:hover {
  background-color: var(--hover-background);
  transform: scale(1.05);
}

/* Back Button Styling */
.mobile-back-button {
  background-color: var(--primary-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: bold;
  width: 95%;
}

.mobile-back-button:hover {
  background-color: var(--hover-background);
  transform: scale(1.02);
}

.main-content.expanded {
  margin-left: 250px;
}

.main-content.collapsed {
  margin-left: 45px;
}


@media screen and (max-width: 768px) {
  .main-content {
    background-color: var(--content-background);

    flex-grow: 1;
    height: 100%;
    transition: all 0.3s ease;
    margin-left: 0;
  }


  .main-content.expanded {
    margin-left: 0;
  }

  .main-content.collapsed {
    margin-left: 0;
  }
}