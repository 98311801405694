.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--content-background);
  /* Use the custom background color */
z-index: 9998;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
  box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: relative;
  background-color: #1b1b1b;
  width: 100%;
  min-width: 240px;
  z-index: 1;
  border-radius: 4px;
  transition: width .25s, min-width .25s;
}

.dark-popup .mapboxgl-popup-content {
  background: #1e1e1e;
  /* Dark background */
  color: #fff;
  /* White text */
  border-radius: 8px;
  padding: 10px;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  width: 400px;
}

.popup-content {
  display: flex;
  align-items: center;
}

.popup-thumbnail img {
  width: 150px;
  height: 100px;
  border-radius: 8px;
  margin-right: 10px;
}

.popup-info h4 {
  font-size: 14px;
  margin: 0 0 5px;
  color: var(--earf-blue);
}

.popup-info .popup-tags {
  margin-top: 5px;
}

.popup-info .wikidata {
  font-size: 12px;
  margin: 0 0 5px;
  color: #bbb;
  /* Light grey for metadata */
}
.cartel-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Arial, sans-serif;
  line-height: 1.1; /* Tightly packed */
}

.cartel-overlay h3 {
  color: red;
  font-size: 16px;
  margin-bottom: 0px;
}

.cartel-state {
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 0px;
}

.cartel-state img {
  width: 14px;
  height: 10px;
  object-fit: cover;
  border-radius: 2px;
}

.cartel-controlled {
  font-size: 12px;
  color: #ccc;
  margin-bottom: 0px;
}

.cartel-name {
  font-size: 14px;
  font-weight: bold;
  color: #ffcc00; /* Yellow color for unvisited links */
  margin-bottom: 5px;
  cursor: pointer; /* Makes it behave like a clickable link */
  text-decoration: none; /* Removes underline */
}

.cartel-name a:hover {
  color: #7a6e3d; /* Ensure yellow color stays on hover */
  text-decoration: none; /* Keep no underline on hover */
}
.cartel-name a {
  text-decoration: none; /* Keep no underline on hover */
    color: #ffcc00 !important; /* Force yellow color for visited links */

}
/* Explicitly define styles for visited links */
.cartel-name a:visited {
  color: #ffcc00 !important; /* Force yellow color for visited links */
  text-decoration: none !important; /* Ensure no underline for visited links */
}

/* Remove default link styling (blue color and underline) */
.cartel-name, .cartel-name a:visited {
  color: #ffcc00; /* Make both normal and visited link yellow */
  text-decoration: none; /* Remove underline */
}


.popup-tag {
  display: inline-block;
  background: #333;
  color: #fff;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 4px;
  margin-right: 5px;
}

.popup-channel {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.popup-channel-thumbnail img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* Circular styling for the channel thumbnail */
  margin-right: 5px;
}

.popup-channel-name {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
}

.toast-header {
  font-size: 13px;
  font-weight: bold;
  color: red;
  padding: 0;
  margin: 0;
}

.toast-content {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.904);
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.toast-time {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.781);
  padding: 0;
  margin: 0;
}

.mapboxgl-ctrl-geocoder--input {
  font: inherit;
  width: 100%;
  border: 0;
  background-color: transparent;
  margin: 0;
  height: 50px;
  color: white;
  padding: 6px 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none;
}

.mapboxgl-ctrl-geocoder--input:focus {
  color: white;
  outline: 0;
  box-shadow: none;
  outline: thin dotted;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right>* {
  z-index: 2;
  position: absolute;
  right: 8px;
  top: 7px;
  display: none;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width .25s, min-width .25s;
}

.mapboxgl-ctrl-geocoder .suggestions {
  background-color: #1b1b1b;
  border-radius: 4px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 110%;
  top: calc(100% + 6px);
  z-index: 1000;
  overflow: hidden;
  font-size: 15px;
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions>li>a {
  cursor: default;
  display: block;
  padding: 6px 12px;
  color: white;
}

.mapboxgl-ctrl-geocoder .suggestions>.active>a,
.mapboxgl-ctrl-geocoder .suggestions>li>a:hover {
  color: white;
  background-color: var(--hover-background);
  text-decoration: none;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  fill: rgba(104, 223, 223, 0.733);
  top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 13px;
  left: 12px;
  width: 23px;
  height: 23px;
}

.mapboxgl-ctrl-geocoder--button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: #fff;
  line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: #909090;
}

.mapboxgl-ctrl-geocoder--icon-geolocate {
  width: 22px;
  height: 22px;
  margin-top: 6px;
  margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0px;
  -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.mapboxgl-ctrl-geocoder--powered-by {
  display: block;
  float: left;
  padding: 6px 12px;
  padding-bottom: 9px;
  font-size: 13px;
}

.mapboxgl-ctrl-geocoder--powered-by a {
  color: #909090;
}

.mapboxgl-ctrl-geocoder--powered-by a:not(:hover) {
  text-decoration: none;
}
.mapboxgl-ctrl-geolocate {
  background-color: #1e1e1e !important; /* Dark background */
  color: #ffffff !important; /* White text */
  border: none; /* Subtle border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6) !important; /* Dark shadow */
}

.mapboxgl-ctrl-geolocate:hover {
  background-color: #333333 !important; /* Slightly lighter background on hover */
}

.mapboxgl-ctrl-geolocate:focus {
  outline: none !important; /* Remove default focus outline */
  box-shadow: 0 0 4px 2px rgba(255, 255, 255, 0.4) !important; /* Custom focus effect */
}

.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  filter: brightness(0) saturate(100%) invert(73%) sepia(34%) saturate(534%) hue-rotate(140deg) brightness(94%) contrast(89%) !important; /* Custom blue color */
}

.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon:hover {
  filter: brightness(0) saturate(100%) invert(73%) sepia(34%) saturate(534%) hue-rotate(140deg) brightness(110%) contrast(90%) !important; /* Slightly brighter blue on hover */
}

.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate--active {
  background-color: #2a2a2a !important; /* Different background color when active */
  color: #ffffff !important; /* White text */
}

.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-geolocate-heading {
  background-color: #444 !important; /* Heading background for user heading icon */
  color: #fff !important; /* Heading icon color */
}

.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate--error {
  color: #ffffff !important; /* White text/icon on error */
}

.mapboxgl-ctrl-geolocate:disabled {
  background-color: #555 !important; /* Disabled background */
  color: #aaa !important; /* Disabled icon color */
  cursor: not-allowed !important; /* Show disabled cursor */
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 640px) {

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    width: 36px;
    min-width: 36px;
  }

  .mapboxgl-ctrl-geocoder {
    width: 33.3333%;
    font-size: 15px;
    line-height: 20px;
    max-width: 360px;
  }

  .mapboxgl-ctrl-geocoder .suggestions {
    font-size: 13px;
  }

  .mapboxgl-ctrl-geocoder--icon {
    top: 8px;
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 0;
  }

  .mapboxgl-ctrl-geocoder--icon-geolocate {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    margin-right: 0;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    left: 7px;
    width: 20px;
    height: 20px;
  }

  .mapboxgl-ctrl-geocoder--input {
    height: 36px;
    padding: 6px 35px;
  }

  .mapboxgl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-right: -5px;
  }

  .mapbox-gl-geocoder--error {
    color: #909090;
    padding: 6px 12px;
    font-size: 16px;
    text-align: center;
  }

  .mapboxgl-ctrl-geocoder--powered-by {
    font-size: 11px !important;
  }
}