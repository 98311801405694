/* Main container for the admin dashboard */
.dashboard-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    position: relative; /* For positioning the logout button */
    background-color: var(--content-background);

}

/* Top-right logout button */
.logout-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #c82333;
}

/* Dashboard header with logo and title */
.dashboard-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}

/* Title container for logo and title */
.title-container {
    display: flex;
    align-items: center;
}

/* Admin dashboard title */
.dashboard-title {
    font-size: 36px;
    margin-left: 15px;
    color: white;
}

/* Highlight the word "Admin" */
.admin-highlight {
    color: rgba(104, 223, 223, 0.733); /* Custom RGB color for Admin */
}

/* Dashboard logo */
.dashboard-logo {
    width: 50px;
    height: auto;
}

/* Container for the dashboard items (buttons) */
.dashboard-items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    width: 100%;
    max-width: 100%;
}

/* Individual dashboard item */
.dashboard-item {
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    text-align: left;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
    width: 250px;
    height: 60px; /* Set to auto to accommodate varying heights */
    display: flex;
    align-items: center;
    background-color: #141517;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
}

/* Image/icon on the left side of the button */
.item-icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

/* Container for the text content (h3 and p) */
.item-content {
    display: flex;
    flex-direction: column; /* Stack h3 and p vertically */
    justify-content: center; /* Vertically center-align the content */
}

/* Hover effect for the dashboard item */
.dashboard-item:hover {
    background-color: var(--hover-background);
    transform: translateY(-5px); /* Lifting effect on hover */
    color: black;
}

.dashboard-item h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: #ffffff;
}

.dashboard-item p {
    margin:0; /* Add some margin to the top to space it from the h3 */
    padding: 0;
    font-size: 13px;
    color: #c7c4c4;
}
/* Media queries for mobile responsiveness */

/* Small devices (tablets and phones) */
@media (max-width: 768px) {
    .dashboard-title {
        font-size: 24px; /* Reduce the title size */
    }

    .dashboard-logo {
        width: 40px; /* Reduce the logo size */
    }

    .dashboard-items {
        flex-direction: column; /* Stack items vertically */
        align-items: center;
    }

    .dashboard-item {
        width: 100%; /* Make items take full width on smaller screens */
        max-width: 300px; /* Set a maximum width to prevent items from being too wide */
    }

    .logout-button {
        top: 10px;
        right: 10px;
        font-size: 14px; /* Reduce button font size */
        padding: 8px 16px; /* Reduce padding */
    }
}

/* Extra small devices (phones) */
@media (max-width: 480px) {
    .dashboard-title {
        font-size: 20px; /* Further reduce title size */
    }

    .dashboard-logo {
        width: 35px; /* Reduce logo size for very small screens */
    }

    .dashboard-item {
        width: 100%; /* Full width for dashboard items */
        max-width: 250px;
    }

    .logout-button {
        font-size: 12px;
        padding: 6px 12px; /* Smaller padding for tiny screens */
    } }