/* Container for the entire country section */
.country-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
margin-bottom: 50px;

}

/* Header section with title and View All button */
.country-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
}

/* Style for the header title */
.country-row-title {
    font-size: 20px;
    color: white;
    font-weight: bold;
}

/* Button style for View All */
.country-view-all-btn {
    padding: 10px 20px;
    background-color: #33333300;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    white-space: nowrap;
    border: none;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}
.country-view-all-btn:hover {
    color: var(--earf-blue);
}

/* Main container for the row of countries */
.country-row-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start; /* Ensure items align to the start */
    gap: 10px;
    padding-bottom: 10px;
    width: 100%;
    overflow-x: hidden; /* Prevents horizontal scroll */
    text-align: left;
}

/* Each individual country item */
.country-item-wrapper {
    flex: 1 0 150px; /* Flex to grow or shrink based on available space */
    max-width: 180px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: white;
    text-align: left;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    
}

.country-item-wrapper:hover {
    transform: scale(1.02); /* Slight hover effect */
}

/* Style for country flags */
.country-flag {
    width: 100%; /* Full width for the flag */
    height: 100px; /* Rectangular flag height */
    object-fit: cover; /* Keep the image aspect ratio */
    border-radius: 8px;
    transition: filter 0.3s ease, opacity 0.3s ease;

}

/* Style for country name */
.country-name {
    margin-top: 2px;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
}




.loading {
    filter: blur(20px); /* Apply blur when loading */
    opacity: 0.5; /* Lower opacity when loading */
}

.loaded {
    filter: none; /* Remove blur after loaded */
    opacity: 1; /* Full opacity after loaded */
}

.image-placeholder {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.2); /* Grey placeholder */
    filter: blur(20px);
}
