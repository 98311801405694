/* Container for the Terms of Service page */
.terms-container {
    padding: 20px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: var(--content-background);
    height: 100%;
}

/* Header section styling */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Terms of Service header */
.terms-header {
    font-size: 25px;
    font-weight: bold;
    color: var(--secondary-text-color); /* Adjust this variable based on your theme */
}

/* Main content section */
.terms-content {
    line-height: 1.6;
    font-size: 16px;
    color: white;
    overflow-y: auto;
    height: calc(100vh - 150px); /* Allow content to scroll if it exceeds the viewport */
}

/* Section titles */
.terms-content h3 {
    font-size: 18px;
    margin-top: 20px;
    color: var(--secondary-text-color);
}

/* Unordered lists styling */
.terms-content ul {
    margin-left: 20px;
}

.terms-content ul li {
    margin-bottom: 10px;
}

/* Bold text styling */
.terms-content p strong {
    font-weight: bold;
    color: var(--secondary-text-color);
}

/* Links styling */
.terms-content a {
    font-weight: bold;
    color: var(--secondary-text-color);
    text-decoration: none;
}

