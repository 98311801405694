.similar-videos-sidebar {
  height: 100%;
  flex: 0 0 20%; /* Default flex-basis for larger screens */
  max-width: 400px; /* Limit the maximum width of the sidebar */
  width: 100%;
  background-color: var(--primary-background);
  margin: 0 auto;
  padding-top: 0;
  border-left: 0.5px solid black; /* Adds a small black border on the left */
}
  .similar-videos-sidebar h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    color: white;
  }

  
  .similar-video-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.2s;
    padding: 5px;
    border-radius: 5px;
  }
  
  .similar-video-item:hover {
    background-color: var(--hover-background);
  }
  
  .highlight-blue {
    color: var(--earf-blue);
  }
  
  .similar-video-thumbnail {
    width: 120px; /* Equal width and height for a perfect square */
    height: 80px;
    object-fit: fill; /* Ensure the image maintains aspect ratio within the square */
    border-radius: 4px;
    margin-right: 10px;
    flex-shrink: 0; /* Prevents thumbnail from shrinking */
  }
  
  .similar-video-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
  }
  
  .similar-video-content p {
    font-weight: 600;
    margin: 0;
    line-height: 1.4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit title to 2 lines */
    -webkit-box-orient: vertical;
  }
  .channel-thumbnail {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
  }
  
  .channel-name {
    font-size: 10px;
    font-weight: bold;
    color: rgb(226, 224, 224);
    text-decoration: none;
  }
  .sidebar-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 5px;
    
  }
  .sidebar-scrollable-content {
    overflow-y: auto;
    flex-grow: 1;
    overflow-y: scroll;
    height: calc(100% - 64px);
    padding: 10px;

  }
  
  /* Hide the scrollbar in the scrollable content */
  .sidebar-scrollable-content::-webkit-scrollbar {
    display: none;
  }
  /* Fade-in effect */
.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
  .name {
    font-size:14px;
    color: white; /* Light grey color */
    margin-top: 2px;
  }
  .wikidata {
    font-size:12px;
    color: rgb(221, 221, 221); /* Light grey color */
    margin-top: 2px;
  }
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  .sidebar-scrollable-content {
    scrollbar-width: none; /* For Firefox */
  }
  .similar-video-header-container {
    margin-top: 5px;
    margin-bottom: 5px; /* Space below the container */
    padding: 10px; /* Inner padding for content */
    position: relative; /* Required for pseudo-element positioning */
  }
  
  .similar-video-header-container::after {
    content: '';
    display: block;
    width: calc(100% - 20px); /* Leaves a 10px gap on each side */
    height: 1px; /* Thickness of the border */
    background-color: rgb(71, 71, 71); /* Border color */
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 10px; /* Starts the border 10px from the left edge */
    right: 10px; /* Ends the border 10px from the right edge */
  }

  .similar-video-header-container h3, 
  .similar-video-header-container h4 {
    margin: 0; /* Remove all default margins */
    padding: 0; /* Remove all padding */
    font-size: 16px; /* Set font size */
    text-align: center; /* Center align text */
  }
  .similar-video-header-container h4 {
    font-size: 14px; /* Set font size */

  }
  .sidebar-tag {
    background-color: #18181b;
    padding: 4px 8px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 10px;
  }

  /* Mobile adjustments */
  @media (max-width: 768px) {
    .similar-videos-sidebar {
display: none;

    }
    .sidebar-scrollable-content {
        max-height: calc(100vh - 100px); /* Adjust as needed for the header */
      }
    .similar-video-item {
      flex-direction: row;
      padding: 8px 5px;
    }
  
    .similar-video-thumbnail {
      width: 100px;
      height: 56px;
      margin: 0;
      border-radius: 4px;
      flex-shrink: 0;
    }
  
    .similar-video-content {
      flex-grow: 1;
      padding-left: 10px;
    }
  
    .similar-video-content p {
      font-size: 13px;
      line-height: 1.2;
      margin-bottom: 5px;
      -webkit-line-clamp: 2;
    }
  
    .sidebar-tags {
      gap: 4px;
      margin-top: 4px;
    }
  
    .sidebar-tag {
      font-size: 9px;
      padding: 3px 6px;
    }
  }
  