/* Content Wrapper Styling */
.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 10px;
  background-color: var(--content-background);

}


.radio-browse-title {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
padding: 10px;
margin-top: 0;
margin-bottom: 20px;

}
/* Country List Styling */
.country-list {
  display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Use auto-fill to avoid spreading items too much */
    justify-items: start; /* Ensure items start from the left */
    width: 100%;
    box-sizing: border-box;
    gap: 10px !important;
}

/* Individual Country and Station Item Styles */
.radio-country-item {
  position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 300px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 0 auto; /* Center align */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.281);

}
.radio-country-item:hover {
  transform: scale(1.02); /* Subtle scaling on hover */
}

/* Decorative line animation on hover */
.radio-country-item::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: var(--secondary-text-color); /* Change color as needed, e.g., #f39c12 */
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.flag-icon {
  width: 100%;
  height: auto;
  transition: filter 0.3s ease;
}

.blur {
  filter: blur(10px); /* Initial blur effect */
}

.flag-icon:not(.blur) {
  filter: none; /* Remove blur when loaded */
}

.radio-country-item:hover::after {
  transform: scaleX(1); /* Line animation when hovered */
}
/* Flag Image Styling */
.radio-country-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}
/* Country Info Container Styling */
.country-info {
  margin-top: 10px;
  text-align: left;
  width: 100%;
  margin-left: 5px;
}

.country-name {
  font-weight: bold;
  font-size: 16px;
}

/* Additional styling for station count */
.station-count {
  font-size: 12px;
  color: var(--secondary-text-color);
  margin-top: 2px;
  margin-bottom: 5px;

}


/* Adjust gap for larger screens */
@media (min-width: 1024px) {
  .country-list {
    gap: 25px; /* Slightly increase the gap for more spacious layout */
  }
}


/* Styles for smaller mobile devices with screen width 480px or smaller */
@media (max-width: 768px) {
  .country-list {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
  }
/* Content Wrapper Styling */
.content-wrapper {
  padding: 0px;
}

  .radio-country-item {
    max-width: 200px;
  }

  .radio-country-item img {
    height: 100px; /* Set a specific height for images to maintain consistency */
  }

  .country-info {
    font-size: 14px; /* Adjust text size for better fit on smaller screens */
  }
  .content-wrapper {
    padding: 0;
  }
  
  .radio-country-item {
    max-width: 100%;
    width: 100%;
  }

  .radio-country-item img {
    height: 120px; /* Reduce height even further for smaller devices */
  }

  .country-info {
    font-size: 12px; /* Reduce font size for very small screens */
  }


}

