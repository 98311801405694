.serverLogContainer {
    max-width: 500px;
    margin: 20px auto; /* Center the container */
    padding: 10px;
    background-color: #141517;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow for a modern look */
    position: absolute;
    top: 150px;
    left: 20px;
}

/* Header */
.serverLogHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.title {
    margin-right: 5px;
    color: white;
    font-weight: 600;
    font-size: 18px; /* Slightly larger for better readability */
}

.serverUrl {
    color: #cfd1d4;
    font-size: 12px; /* Slightly larger for mobile readability */
    font-weight: 500;
}

/* Tabs */
.tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
    gap: 5px; /* Add spacing between tabs */
    margin-bottom: 10px;
}

.tabButton {
    background-color: #1e2023;
    border: none;
    color: #cfd1d4;
    font-size: 12px;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex: 1; /* Make all tabs equally wide */
    text-align: center;
}

.tabButton:hover {
    background-color: #282a2d;
}

.activeTab {
    background-color: var(--earf-blue);
    color: black;
}

/* Scrollable log container */
.logContainer {
    max-height: 600px;
    overflow-y: auto;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #1e2023;
    padding: 5px; /* Padding to separate logs from edges */
}

/* Table styles */
.table {
    width: 100%;
    border-collapse: collapse;
}

.row td {
    padding: 8px 10px;
    border-bottom: 1px solid #333;
    color: white;
    font-size: 14px;
    word-wrap: break-word;
}

/* Timestamp */
.timestamp {
    font-size: 12px;
    color: #f0f0f0;
    margin-bottom: 2px;
    display: block; /* Ensure it appears above the message */
}

/* Log Message */
.message {
    font-size: 14px;
    color: white;
}

/* Differentiation for log types */
.row[data-log-type="LOGIN ATTEMPT"] {
    background-color: #b3742c67;
}

.row[data-log-type="UPDATER"] {
    background-color: #7347a53d;
    color: #cce7ff;
}

.row[data-log-type="CREATER"] {
    background-color: #3d8048;
    color: #a8ffab;
}

.row[data-log-type="DELETER"] {
    background-color: #522727;
    color: #ffabab;
}

.row[data-log-type="SITEMAP"] {
    background-color: #2d2d40;
    color: #ccccff;
}

/* Loading and error messages */
.loading,
.error {
    text-align: center;
    color: #666;
    font-size: 16px;
    margin-top: 20px;
}

/* Responsive styles for tablets and smaller screens */
@media (max-width: 768px) {
    .serverLogContainer {
        max-width: 95%; /* Take up most of the screen width */
        padding: 15px;
        margin: 10px auto;
        position: relative; /* Use relative positioning for better layout flow */
    }

    .logContainer {
        max-height: 400px;
    }

    .title {
        font-size: 16px;
    }

    .tabButton {
        font-size: 10px;
        padding: 6px 8px;
    }

    .row td {
        font-size: 12px; /* Reduce log font size for smaller screens */
    }
}

/* Responsive styles for mobile phones */
@media (max-width: 480px) {
    .serverLogContainer {
        max-width: 100%; /* Take up the full screen width */
        padding: 10px;
        margin: 0; /* No margin for a cleaner look on small screens */
    }

    .tabs {
        flex-direction: column; /* Stack tabs vertically */
        gap: 8px;
    }

    .tabButton {
        font-size: 9px;
        padding: 5px;
    }

    .logContainer {
        max-height: 300px;
    }

    .title {
        font-size: 14px; /* Reduce title size */
    }

    .row td {
        font-size: 11px;
        padding: 6px 8px; /* Reduce padding for smaller screens */
    }
}
