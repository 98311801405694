.home-page-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 30px;
    height: calc(100vh - 80px); /* Full viewport height minus header */
    overflow-y: scroll; /* Enable vertical scrolling */
    margin-bottom: 50px;
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    background-color: var(--content-background);
}

.home-page-container::-webkit-scrollbar {
    display: none;  /* Hide scrollbar in Chrome, Safari, and Opera */
}
.spacer-line1 {
    display: block;
    height: 1px;
    width: 100%;
    background-color: rgb(66, 65, 65); /* Temporarily for debugging */
    opacity: 0.8;
    margin: 20px 0; /* Add spacing */
    z-index: 9999;
}
