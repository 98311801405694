/* Wildfire Header */
.wildfire-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: white;
}

/* Wildfire List */
.wildfire-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc(100vh - 270px); 
    overflow-y: auto;  
}

/* Wildfire Card */
.wildfire-card, .most-recent-wildfire-card {
    border-radius: 10px;
    padding: 10px;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: white;
}

/* Wildfire Card Hover Effect */
.wildfire-card:hover, .most-recent-wildfire-card:hover {
    background-color: var(--hover-background);
}

/* Wildfire Card Content */
.wildfire-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
}

/* Wildfire Info Container */
.wildfire-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
    margin-right: 10px; 
    color: white;
}

/* Wildfire Title */
.wildfire-title, .most-recent-wildfire-title {
    font-size: 18px;
    color: white;
    margin: 0;
}

/* Wildfire Time (Ongoing or Closed Date) */
.wildfire-time, .most-recent-wildfire-time {
    font-size: 14px;
    color: #ccc;
    margin-top: 5px;
}

/* More Info Button */
.wildfire-map-button {
    background-color: var(--primary-background);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    white-space: nowrap; /* Prevent button text from wrapping */
    flex-shrink: 0; /* Prevent the button from shrinking */
    display: inline-block;
}

/* Hover effect for the button */
.wildfire-map-button:hover {
    color: var(--earf-blue);
    background-color: #222; /* Add a hover background for the button */
}



/* Make the button and layout responsive */
@media (max-width: 768px) {
    .wildfire-map-button {
        padding: 6px 10px; /* Smaller padding on mobile */
        font-size: 12px;
    }
    .wildfire-header {
        font-size: 12px;
    }
    
    .wildfire-title {
        font-size: 16px; /* Adjust title size for mobile */
    }

    .wildfire-time {
        font-size: 12px; /* Adjust time size for mobile */
    }

}
