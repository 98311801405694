/* StreamDescription.module.css */
.description {
  width: 95%;
  /* Adjusted width for better centering */
  border-radius: 10px;
  line-height: 1.6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  /* Centered with space above and below */
  margin-bottom: 50px;
  position: relative;
  background-color: #18181b;
  padding: 20px;
}


.descriptionnotice {
  color: rgba(196, 196, 196, 0.658) !important;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  font-style: italic;
  text-align: right !important;
  position: absolute;
  bottom: 5px;
  right: 0px;
}

.description h2 {
  color: white;
  /* Name styled with the Earf blue color */
  font-size: 18px;
  /* Adjust header size */
}

.description p {
  color: #d3d3d3;
  /* Greyer white for description text */
  font-size: 1rem;
  /* Smaller font for description */
  margin: 0;
  /* Remove default margins */
  text-align: left;
  margin-bottom: 10px;
  margin-right: 10px;

}

.highlight-blue {
  color: var(--earf-blue);
}

.about-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 0;
  padding: 0;
}

.about-header {
  margin: 0;
  padding: 0;
  line-height: 1;
  /* Adjust line height if needed */
}


/* StreamDescription.module.css */
.flag {
  margin-left: 5px;
  vertical-align: middle;
  width: 35px;
  height: 20px;
}

/* Header links container */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* StreamDescription.module.css */

/* Container for links with tooltips */
.linksContainer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

/* Individual link styling */
.link {
  position: relative;
  display: inline-block;
}


.link::after {
  content: attr(title); /* Use the title attribute for tooltip text */
  position: absolute;
  top: 50%; /* Center vertically */
  right: 110%; /* Position to the left of the link */
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
}


/* Show tooltip on hover */
.link:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Icon styling */
.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* Slight scale-up effect on hover */
.link:hover .icon {
  transform: scale(1.1);
}


/* Mobile adjustments */
@media (max-width: 768px) {
  .description {
    padding: 15px;
    /* Reduce padding for mobile */
    margin-bottom: 30px;
    /* Adjust bottom margin for mobile */
  }

  .description h2 {
    font-size: 14px;
    /* Smaller font size for the header */
    margin-bottom: 8px;
    /* Adjust space for mobile */
  }

  .description-image {
    width: 150px;
    height: auto;
    margin-right: 20px;
    border-radius: 8px;
    margin-top: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .description p {
    margin-top: 25px;
    font-size: 0.9rem;
    /* Smaller font size for description */
    line-height: 1.4;
    /* Adjust line height for better readability */
  }
}

@media (max-width: 480px) {
  .description {
    padding: 10px;
    /* Further reduce padding for very small screens */
    margin-bottom: 20px;
    /* Adjust bottom margin for very small screens */
  }

  .description-image {
    width: 50px;
    height: auto;
    margin-right: 20px;
    border-radius: 8px;
    margin-top: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }


  .description h2 {
    font-size: 12px;
    /* Smaller font size for the header */
  }

  .description p {
    font-size: 0.85rem;
    /* Smaller font size for description */
  }
}