.earthquake-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-wrap: wrap; /* Allows content to wrap on smaller screens */
}

.highest-recently {
  border: 2px solid red; /* Red border to highlight the highest magnitude quake */
  border-radius: 10px;
  margin-left: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  min-width: 300px; /* Reduced min-width for better responsiveness */
  flex-wrap: wrap; /* Allow items to wrap inside this container */
  max-width: 100%; /* Ensure it doesn't exceed container width */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.highest-recently-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Ensure it stretches the entire width */
  flex-wrap: wrap; /* Ensure flexibility on small screens */
}

.earthquake-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 280px); /* Subtract any other heights like header/footer */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Add some padding to avoid right overflow */
  box-sizing: border-box;
}

.earthquake-card {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping inside the card */
}

.earthquake-card:hover {
  background-color: var(--hover-background);
}

.earthquake-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; /* Ensure content wraps on small screens */
}

.earthquake-info {
  flex: 1;
}

.highest-earthquake-title,
.earthquake-title {
  font-size: 16px;
  color: white;
  margin: 0;
}

.highest-earthquake-time,
.earthquake-time {
  font-size: 14px;
  color: #ccc;
  margin-top: 5px;
}

.earthquake-more-info-button {
  background-color: var(--primary-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
}

.earthquake-more-info-button:hover {
  color: var(--earf-blue);
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .highest-recently {
    margin-left: 0; /* Remove left margin for better fitting on mobile */
    min-width: 100%; /* Allow it to take full width on smaller screens */
  }

  .earthquake-card {
    padding: 8px; /* Reduce padding for mobile */
  }
  .earthquake-header h2 {
font-size: 16px;
  }
  
  .earthquake-more-info-button {
    padding: 6px 10px; /* Reduce button size for mobile */
    font-size: 12px;
  }

  .highest-earthquake-title,
  .earthquake-title {
    font-size: 14px; /* Smaller title font on mobile */
  }

  .highest-earthquake-time,
  .earthquake-time {
    font-size: 12px; /* Smaller time font on mobile */
  }

  .earthquake-list {
    padding-right: 0; /* Remove extra padding on small screens */
  }
}
