/* Main container for the Stream Checker page */
.stream-checker-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  color: white;
  width: 100%;
  background-color: var(--content-background);

}

/* Header container with back button on the right */
.header-container {
  display: flex;
  justify-content: space-between;
  /* Align items on left and right */
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.youtube-search-button {
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  background: none;

}

.youtube-icon {
  width:50px;
  height: 50px;
  transition: transform 0.3s ease;
}

.youtube-icon:hover {
  transform: scale(1.05);
}
/* Styling for the logo and title */
.logo {
  width: 50px;
  height: auto;
  margin-right: 15px;
}

h1 {
  font-size: 20px;
  margin: 0;
  color: white;
}

.search-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.search-input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #444;
  /* Subtle dark border */
  border-radius: 5px;
  background-color: #333;
  /* Dark background */
  color: #fff;
  /* Light text color */
  transition: all 0.3s ease;
}

.search-input::placeholder {
  color: #bbb;
  /* Lighter placeholder color */
}

.search-input:focus {
  border-color: #0078d7;
  /* Highlight color on focus */
  outline: none;
  background-color: #222;
  /* Slightly darker on focus */
}



/* Filter container */
.filter-container {
  margin-bottom: 15px;
}

.filter-select {
  padding: 5px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* Ensures it spans the full width */
  padding: 10px 0;
}

.download-buttons {
  display: flex;
  gap: 10px;
  /* Adds space between the download buttons */
}

.restore-container {
  text-align: right;
  /* Centers the restore button */
  flex-grow: 2;
  /* Makes sure the restore button stays centered */
}

/* Optional: Add more button-specific styling if needed */
.restore-button {
  background-color: #dc3545;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;

}

.restore-button:hover {
  background-color: #c82333;
}

.download-button {
  background-color: var(--button-color);
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.download-button:hover {
  background-color: var(--hover-background);
}


/* Stream column and item styling */
.stream-columns-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

}

.fallbackButton {
  padding: 8px 12px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
}

.fallbackButtonGreen {
  background-color: green;
}

.fallbackButtonRed {
  background-color: red;
}

.stream-header {
  font-size: 20px;
  font-weight: bold;
  flex: 1;
  text-align: center;
  color: white;
}

/* Stream columns */
.stream-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;

}

/* Stream item styling */
.stream-column {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  max-height: 80vh;
  overflow-y: auto;

}

.stream-item {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
  color: black;
  cursor: pointer;
}

.online {
  background-color: #d4edda;
  border-left: 5px solid #28a745;
}

.offline {
  background-color: #f8d7da;
  border-left: 5px solid #dc3545;
}

.toggle-status-button {
  background-color: #464645;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
}

.toggle-status-button:hover {
  background-color: #ff8700;
}


.stream-info {
  flex: 1;
  padding-right: 10px;
  font-size: 14px;
}

.delete-button {
  background-color: red;
  border: none;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.delete-button:hover {
  background-color: darkred;
}

.delete-button i {
  font-size: 15px;
}
.edit-button {
  background-color: white;
  color: black;
  padding: 8px 12px;
  margin-top: 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}

.edit-button:hover {
  background-color: #333; /* Slightly darker white for hover effect */
  color: white;

}



.stream-info h3 {
  font-size: 16px;
  margin: 0;
  color: #333;
}

.stream-tags {
  margin-top: 5px;
}

.tag {
  background-color: #4e4e4e;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.tag:hover {
  background-color: #3b3b3b;
}

/* Check Stream, Mark Fixed, and Watch buttons */
.check-stream-button,
.mark-fixed-button,
.watch-button {
  background-color: #329dcf;
  color: black;
  padding: 8px 12px;
  margin-top: 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
}

.mark-fixed-button {
  background-color: #28a745;
}

.watch-button {
  background-color: #63488dc4;
  color: white;
}

.watch-button:hover {
  background-color: #7d73a0;
}

/* Hide scrollbars */
.stream-column::-webkit-scrollbar {
  display: none;
}

.stream-column {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  height: 90%;
  max-height: 90%;
  background: #1e1e1e; /* Dark background for modal */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.status-toggle {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}
.status-online {
  background-color: green;
  color: white;
}
.status-offline {
  background-color: red;
  color: white;
}

.modal-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 15px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  color: #ffffff;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.form-group input {
  padding: 8px 10px;
  border: 1px solid #444;
  border-radius: 4px;
  background: #2e2e2e;
  color: #ffffff;
  font-size: 1rem;
}

.form-group input:focus {
  outline: none;
  border-color: #00aaff;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.close-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.save-button {
  background: #00aaff;
  color: #ffffff;
}

.save-button:hover {
  background: #0088cc;
}

.close-button {
  background: #444;
  color: #ffffff;
}

.close-button:hover {
  background: #555;
}

@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr; /* Single column on small screens */
  }
}
