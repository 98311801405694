.country-profile-editor-container {
    background-color: var(--content-background);
    color: var(--text-color);
    padding: 20px;
    min-height: 100vh;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 8px;
    background-color: #222;
    color: #fff;
}

.profiles-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-height: calc(100vh - 150px);
    /* Adjustable to fit available space */
    overflow-y: auto;
    /* Enable vertical scrolling */
    padding-right: 10px;
    /* Add padding for scrollbar visibility */
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #555 #333;
    /* For Firefox */
}

/* For Chrome, Edge, and Safari */
.profiles-list::-webkit-scrollbar {
    width: 8px;
}

.profiles-list::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 8px;
}

.profiles-list::-webkit-scrollbar-track {
    background-color: #333;
}


.profile-item {
    flex: 1 1 calc(33.333% - 20px);
    background-color: #333;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.profile-actions {
    display: flex;
    justify-content: space-between;
}

.edit-button,
.delete-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.edit-button {
    background-color: #007bff;
    color: #fff;
}

.delete-button {
    background-color: #dc3545;
    color: #fff;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    height: 90%;
    max-height: 90%;
    background: #1e1e1e; /* Dark background for modal */
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .status-toggle {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }
  .status-online {
    background-color: green;
    color: white;
  }
  .status-offline {
    background-color: red;
    color: white;
  }
  
  .modal-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
  
  .edit-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 15px;
    width: 100%;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    color: #ffffff;
    margin-bottom: 8px;
    font-size: 0.9rem;
  }
  
  .form-group input {
    padding: 8px 10px;
    border: 1px solid #444;
    border-radius: 4px;
    background: #2e2e2e;
    color: #ffffff;
    font-size: 1rem;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: #00aaff;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .save-button,
  .close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .save-button {
    background: #00aaff;
    color: #ffffff;
  }
  
  .save-button:hover {
    background: #0088cc;
  }
  
  .close-button {
    background: #444;
    color: #ffffff;
  }
  
  .close-button:hover {
    background: #555;
  }
.form-group {
    flex: 1 1 calc(50% - 20px);
    /* Two-column layout with spacing */
    display: flex;
    flex-direction: column;

}

.form-group label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--text-color);
}

.form-group input {
    padding: 10px;
    border: 1px solid #444;
    border-radius: 6px;
    background-color: #222;
    color: #fff;
    font-size: 14px;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;
}

.save-button {
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
}

@media (max-width: 768px) {
    .modal-content {
        width: 95%;
        height: 95%;
    }

    .form-group {
        flex: 1 1 100%;
        /* Full width on smaller screens */
    }
}