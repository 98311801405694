/* Container for the privacy policy page */
.privacy-policy-container {
    padding: 20px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: var(--content-background);
height: 100%;
  }
  
  /* Header section styling */
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Privacy policy header */
  .privacy-header {
    font-size: 25px;
    font-weight: bold;
    color: var(--secondary-text-color);
  }
  

   /* Main content section */
   .privacy-content {
    line-height: 1.6;
    font-size: 16px;
    color: white;
    overflow-y: auto;
    height: calc(100vh - 150px); /* Allow content to scroll if it exceeds the viewport */
  }
  
  /* Section titles */
  .privacy-content h3 {
    font-size: 18px;
    margin-top: 20px;
    color: var(--secondary-text-color);
  }
  
  /* Unordered lists */
  .privacy-content ul {
    margin-left: 20px;
  }
  
  .privacy-content ul li {
    margin-bottom: 10px;
  }
    /* Effective date styling */
    .privacy-content h3  {
        font-weight: bold;
        color: var(--secondary-text-color);
      }
      
  /* Effective date styling */
  .privacy-content p strong {
    font-weight: bold;
    color: var(--secondary-text-color);
  }
  .privacy-content a  {
    font-weight: bold;
    color: var(--secondary-text-color);
    text-decoration: none;
  }