
  /* Aligns content in the toolbar */
  .location-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #18181b;
    border-radius: 5px;
    padding: 4px 8px; /* Increase padding for more space */
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px; /* Reduce letter spacing for cleaner look */
    gap: 5px; /* Space between child elements */
  }
  

  
    /* Ensure inline layout of LocationInfo and back button */
    .location-info-container {
      display: inline-flex;
      align-items: center;
    }
    
  /* Time section styling */
  .location-time {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  
  /* Weather section styling */
  .location-weather {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;

  }
  
  /* Weather info icon and temperature display */
  .weather-info {
    display: flex;
    align-items: center;
  }
  
  .weather-info img {
    width: 30px; /* Increase size of the weather icon */
    height: 30px;
  }
  
  .weather-details {
    text-align: left;
  }
  
  .weather-details p {
    margin: 0;
  }
  .centeredcontainer {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  max-height: 300px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.descriptionloader {
  width: 35px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #68dfdfbb 90%, #0000) 0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
      clip-path: inset(0 -34% 0 0);
  }
}

  /* Font size adjustments for smaller screens */
  @media (max-width: 768px) {
    .location-time, .weather-details p {
      font-size: 1em;
    }
    
    .location-info-container {
      padding: 8px 15px;
    }
    
    .weather-info img {
      width: 30px;
      height: 30px;
    }
  }
  