
/* Custom position to avoid overlapping sidebar */
.Toastify__toast-container {
    z-index: 9999; /* Ensure it appears above other content */
    max-width: 300px; /* Control the width of the toast */
    position: absolute;
    top: 0px;
   width: 100%;
  
  }
.hurricane-toast-progress-bar {
    font-size: 12px;   /* Set the font size for the body */
    line-height: 1.4;  /* Adjust line height if needed */
}

/* Custom progress bar styling for warning toasts */
.Toastify__toast--warning .Toastify__progress-bar {
    background-color: #FFA500; /* Golden-orange color for the progress bar */
}
/* Style the custom icon inside the toast */
.custom-toast-icon {
    width: 25px;  /* Adjust the size of the image */
    height: 25px;
    margin-right: 55px; /* Increase the space between the icon and the text */
    vertical-align: middle; /* Vertically align the icon with the text */
  }
  
  .hurricane-toast {
    font-size: 14px;   /* Adjust font size */
    display: flex;     /* Use flexbox for better control */
    align-items: center; /* Center align icon and text vertically */
  }
  
  .Toastify__toast-body {
    display: flex;   /* Ensure the body of the toast uses flexbox */
    align-items: center;  /* Vertically center the icon and text */
  }