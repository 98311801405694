/* Style the form container */
.contact-container {
  width: 70%;
    max-width: 900px; /* Increased the width to make it less narrow */
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    position: relative; /* To position the back button inside the container */
  }
  
  /* Style the form header */
  .contact-header {
    text-align: center;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    color: white; /* Dark color for better readability */
  }
  
  
  /* Style each label */
  .contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: white; /* Slightly lighter than the header */
  }
  
  /* Style the input fields */
  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px; /* Space between fields */
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: white;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
    background-color: #1e1e1e;
  }
  
  /* Add focus effect to input fields */
  .contact-form input:focus,
  .contact-form select:focus,
  .contact-form textarea:focus {
    outline: none;
    box-shadow: 0 0 5px var(--secondary-text-color); /* Subtle blue glow */
  }
  
  /* Style the textarea specifically */
  .contact-form textarea {
    resize: vertical; /* Allow vertical resizing only */
    min-height: 120px; /* Set a decent default height */
  }
  
  /* Style the submit button */
  .contact-form button {
    width: 100%;
    padding: 15px;
    background-color: var(--secondary-text-color); /* Bootstrap blue */
    color: black;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect for the submit button */
  .contact-form button:hover {
    background-color: #59a1c2; /* Darker blue on hover */
  }
  
  /* Add some spacing at the bottom of the form */
  .contact-form {
    margin-bottom: 40px;
  }
  